<template>
	<section class="section-welcome" id="welcome" ref="welcome">
		<div class="section-welcome__content">
			<div class="section-welcome__title-wrapper">
				<h1 class="section-welcome__title">
					<span class="section-welcome__title-accent section-welcome__title-accent--left" style="padding-right: 4px;">
						<!-- “ -->
					</span>
					<span class="section-welcome__title-text">
						<span class="section-welcome__title-main">
							{{ $t('lnd.s1.t1') }}
						</span><br>
						<span>
							{{ $t('lnd.s1.t2') }}
						</span><br>
						<span>
							{{ $t('lnd.s1.t3') }}
							<span class="section-welcome__title-accent section-welcome__title-accent--right">
								<!-- ” -->
							</span>
						</span>
					</span>
				</h1>

				<div class="section-welcome__buttons">
					<button
						class="button section-future__btn"
						type="button"
						@click="$emit('buy')"
					>
						{{ $t('Buy DexNode') }}
					</button>

					<a
						href="#about"
						class="button button--secondary section-welcome__link"
					>
						{{ $t('Learn More') }}
					</a>
				</div>

				<!-- <a href="#about" class="button section-welcome__link">
					<span>
						{{ $t('Learn More') }}
					</span>
				</a> -->

				<ul class="section-welcome__parthners">
					<li>
						<img src="@/assets/images/partners/parthner-1.svg" alt="partner">
					</li>
					<li>
						<img src="@/assets/images/partners/parthner-2.svg" alt="partner">
					</li>
					<li>
						<img src="@/assets/images/partners/parthner-3.svg" alt="partner">
					</li>
					<li>
						<img src="@/assets/images/partners/parthner-4.svg" alt="partner">
					</li>
					<li>
						<img src="@/assets/images/partners/parthner-5.svg" alt="partner">
					</li>
					<li>
						<img src="@/assets/images/partners/parthner-6.svg" alt="partner">
					</li>
					<li>
						<img src="@/assets/images/partners/parthner-7.svg" alt="partner">
					</li>
					<li>
						<img src="@/assets/images/partners/parthner-8.svg" alt="partner">
					</li>
					<li>
						<img src="@/assets/images/partners/parthner-9.svg" alt="partner">
					</li>
					<li>
						<img src="@/assets/images/partners/parthner-10.svg" alt="partner">
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
import anime from 'animejs'

export default {
	name: 'LndWelcome',
	mounted() {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (!entry.isIntersecting) {
					return
				}

				// When the element comes into view, start the animation

				anime({
					targets: '.section-welcome__title-text > span',
					translateY: ['30px', '0px'], // Animate from -100px to 0px
					opacity: [0, 1],               // Fade in opacity
					delay: anime.stagger(150, { start: 700 }),       // Delay each animation by 200ms
					easing: 'spring(2, 40, 10, 2)',           // Easing for smooth animation
				});

				anime({
					targets: '.section-welcome__title-accent--left',
					translateX: ['-40px', '0px'], // Animate from -100px to 0px
					opacity: [0, 1],               // Fade in opacity
					delay: 700,
					easing: 'spring(2, 40, 10, 2)',           // Easing for smooth animation
				});

				anime({
					targets: '.section-welcome__title-accent--right',
					translateX: ['40px', '0px'], // Animate from -100px to 0px
					opacity: [0, 1],               // Fade in opacity
					delay: 700,
					easing: 'spring(2, 40, 10, 2)',           // Easing for smooth animation
				});

				// anime({
				// 	targets: '.section-welcome__link',
				// 	translateY: ['30px', '0px'], // Animate from -100px to 0px
				// 	opacity: [0, 1],               // Fade in opacity
				// 	delay: 1300,
				// 	easing: 'spring(2, 40, 10, 3)',           // Easing for smooth animation
				// 	duration: 1200                   // Duration for each element's animation
				// });
				anime({
					targets: '.section-welcome__buttons',
					translateY: ['30px', '0px'], // Animate from -100px to 0px
					opacity: [0, 1],               // Fade in opacity
					delay: 1300,
					easing: 'spring(2, 40, 10, 3)',           // Easing for smooth animation
					duration: 1200                   // Duration for each element's animation
				});

				anime({
					targets: '.section-welcome__parthners',
					translateY: ['30px', '0px'], // Animate from -100px to 0px
					opacity: [0, 1],               // Fade in opacity
					delay: 2000,
					easing: 'spring(2, 40, 10, 0)',           // Easing for smooth animation
					duration: 1                   // Duration for each element's animation
				});


				anime({
					targets: '.section-welcome',
					background: [
						`
							linear-gradient(180deg, rgba(0, 0, 0, 0.00) 72%, rgba(0, 0, 0, 0.50) 100%),
							linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),
							radial-gradient(36.91% 47.32% at 19.44% 61.35%, rgba(103, 255, 237, 0.25) 0%, rgba(103, 255, 237, 0.00) 100%),
							radial-gradient(42.38% 54.33% at 81.91% 56.4%, rgba(101, 107, 255, 0.30) 0%, rgba(101, 107, 255, 0.00) 100%),
							radial-gradient(29.88% 38.31% at 50% 72.42%, rgba(49, 206, 255, 0.25) 0%, rgba(49, 206, 255, 0.00) 100%)
						`,
						`
							linear-gradient(180deg, rgba(0, 0, 0, 0.00) 72%, rgba(0, 0, 0, 0.50) 100%),
							linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),
							radial-gradient(36.91% 47.32% at 19.44% 61.35%, rgba(101, 107, 255, 0.30) 0%, rgba(101, 107, 255, 0.00) 100%),
							radial-gradient(42.38% 54.33% at 81.91% 56.4%, rgba(103, 255, 237, 0.25) 0%, rgba(103, 255, 237, 0.00) 100%),
							radial-gradient(29.88% 38.31% at 50% 72.42%, rgba(49, 206, 255, 0.00) 0%, rgba(49, 206, 255, 0.00) 100%)
						`,
					],
					easing: 'easeInOutQuad',
					duration: 3000,
					loop: true,
					direction: 'alternate'
				});

				observer.unobserve(entry.target); // Stop observing once animation starts
			});
		}, { threshold: 0.5 }); // Adjust threshold as needed

		// Target the block for observation
		observer.observe(this.$refs.welcome);
	},
}
</script>
<style scoped lang="scss">
.section-welcome__buttons {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  & > * + * {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .section-welcome__buttons {
    flex-direction: column;

    & > * + * {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

.section-welcome__title-text span,
.section-welcome__title-accent--left,
.section-welcome__title-accent--right {
	display: inline-block;
}

.section-welcome__title-accent--right {
	line-height: 0;
	vertical-align: bottom;
}

// .section-welcome__link {
// 	opacity: 0;
// }
.section-welcome__buttons {
	opacity: 0;
}

.section-welcome__title-text > span {
	opacity: 0;
}

.section-welcome__parthners {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute space between items */
  list-style: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
  opacity: 0;
  margin-top: 60px;
}

.section-welcome__parthners li {
  flex: 1 1 calc(20% - 10px); /* Each item takes 20% of the row width on larger screens */
  margin: 5px; /* Adjust the margin as needed */
  box-sizing: border-box;
}

.section-welcome__parthners img {
  width: 100%; /* Make sure the images scale to their container */
  height: auto; /* Maintain aspect ratio */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
	.section-welcome__parthners {
		justify-content: center; /* Center the items */
	}
  	.section-welcome__parthners li {
		flex: 1 1 calc(25% - 10px); /* Each item takes 33.33% of the row width on mobile */
		max-width: calc(25% - 10px);
 	 }
}
</style>
