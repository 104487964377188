<template>
  <section class="section-part section">
    <div class="container">
      <div class="section-part__content">
        <!-- <img
          src="@/assets/images/dexnet-node-2.png"
          class="section-part__img"
          alt="become a part of dexnet"
        > -->

        <FrameAnimationBlock
          style="text-align: center;"
          :frameCount="28"
          :getFrameUrl="(i) => require(`@/assets/images/animations/animation2S/${(i).toString().padStart(2, '0')} Animation 2-squashed.jpg`)"
          :maxWidth="610"
          :maxHeight="409"
          :startValue="'15% 25%'"
          :endValueDivider="0.8"
        />

        <p class="section__subtitle section-part__subtitle">
          {{ $t('lnd.part.title') }}
        </p>
        <h2 class="section__title section-part__title">
          {{ $t('lnd.part.subtitle') }}
        </h2>

        <button
          class="button section-part__link"
          @click="$emit('buy')"
        >
          <span>
            {{ $t('lnd.buy') }}
          </span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import FrameAnimationBlock from '@/components/lnd/dexnode/children/FrameAnimationBlock.vue';

export default {
  name: 'LndPart',
  components: {
    FrameAnimationBlock,
  },
}
</script>
