<template>
  <section class="section section-future">
    <div class="container">
      <!-- <p class="section__subtitle section-future__subtitle">
        {{ $t('lnd.future.title') }}
      </p> -->

      <h2 class="section__title section-future__title">
        DexNode <span style="font-weight: 400 !important"><br>{{ $t('lnd.future.title') }}</span>
      </h2>

      <button
        class="button section-future__btn"
        type="button"
        @click="$emit('buy')"
      >
        <span>
          {{ $t('lnd.buy') }}
        </span>
      </button>

      <!-- <img src="@/assets/images/dexnet-node.png" alt="meet dex node" class="section-future__img"> -->
      <FrameAnimationBlock
        :frame-count="21"
        :get-frame-url="(i) => require(`@/assets/images/animations/animation3S/${(i).toString().padStart(2, '0')} Aniamtion 3-squashed.jpg`)"
        :max-width="874"
        :max-height="1050"
        :start-value="'17% 27%'"
        :end-value-divider="2.5"
      />
    </div>
  </section>
</template>
<script>
import FrameAnimationBlock from '@/components/lnd/dexnode/children/FrameAnimationBlock.vue';

export default {
  name: 'LndFuture',
  components: {
    FrameAnimationBlock,
  },
}
</script>
