<template>
	<section
		ref="node"
		id="node"
		class="container section-stat section grid-two-columns"
	>
		<div class="grid-two-columns__col-title">
			<div class="section-stat__col-title">
				<h2 class="section__title section-stat__title">
					{{ $t('lnd.node.title') }}
				</h2>
				<p class="section__subtitle section-stat__subtitle">
					{{ $t('lnd.node.subtitle') }}
				</p>
			</div>
		</div>
		<div class="grid-two-columns__col-content section-stat__col-content">
			<ul class="section-stat__list">
				<li class="section-stat__item">
					<h3 class="section-stat__list-val">
						<div style="display: flex; flex-direction: column;">
							<span>{{ counts.storageSize }} PB</span>
							<small style="font-size: 12px;">1 Pb ({{ $t('petabyte') }}) = 1024 Tb</small>
						</div>
					</h3>

					<p class="section-stat__list-desc">
						{{ $t('lnd.node.d1') }}
					</p>
				</li>

				<li class="section-stat__item">
					<h3 class="section-stat__list-val">
						{{ counts.onlineCount }}
					</h3>
					<p class="section-stat__list-desc">
						{{ $t('lnd.node.d2') }}
					</p>
				</li>
			</ul>
		</div>
	</section>
</template>

<script>
import { api } from '@/api'
import anime from 'animejs'

export default {
	name: 'LndNode',
	data() {
		return {
			counts: {
				onlineCount: 0,
				storageSize: 0,
			}
		}
	},
	async mounted() {
		try {
			const { data } = await api.getNodesData()

			if (data) {
				// Using Intersection Observer to trigger the animation on scroll
				const observer = new IntersectionObserver((entries) => {
					entries.forEach(entry => {
						if (!entry.isIntersecting) {
							return
						}

						// When the element comes into view, start the animation

						// Title
						anime({
							targets: '.section-stat__col-title',
							translateX: ['-30px', '0px'], // Animate from -100px to 0px
							opacity: [0, 1],               // Fade in opacity
							delay: 100,       // Delay each animation by 200ms
							easing: 'spring(2, 40, 10, 2)',           // Easing for smooth animation
						});

						// Counts
						anime({
							targets: this.counts,
							storageSize: data.storage_size,
							round: 100,
							duration: 1200,
							easing: 'easeInOutExpo',
						});

						anime({
							targets: this.counts,
							onlineCount: data.online_count,
							round: 1,
							duration: 1200,
							easing: 'easeInOutExpo',
						});

						anime({
							targets: '.section-stat__list',
							translateX: ['30px', '0px'], // Animate from -100px to 0px
							opacity: [0, 1],               // Fade in opacity
							delay: anime.stagger(150, { start: 100 }),       // Delay each animation by 200ms
							easing: 'spring(2, 40, 10, 2)',           // Easing for smooth animation
						});

						observer.unobserve(entry.target); // Stop observing once animation starts
					});
				}, { threshold: 0.5 }); // Adjust threshold as needed

				// Target the block for observation
				observer.observe(this.$refs.node);

			}
		} catch (err) {
			console.debug(
				'%c InternetSection.vue getBalances ERROR:',
				'color:red;',
				err
			)
		}
	},
}
</script>
<style scoped>
.section-stat__col-title,
.section-stat__list {
	opacity: 0;
}
</style>
